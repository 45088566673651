import { LocalStorageService } from './local-storage.service'

import { DOCUMENT } from '@angular/common'
import { EventEmitter, Inject, Injectable, Renderer2, RendererFactory2, signal } from '@angular/core'

export enum ContrastMode {
  Color = 'color',
  Contrast = 'contrast',
}

@Injectable({
  providedIn: 'root',
})
// TODO: delete from body class .contrast
export class AccessibilityService {
  settingsOpen = signal<boolean>(false)

  contrastModeEmitter = new EventEmitter()
  fontSizeLevelChangeEmitter = new EventEmitter()

  contrastMode!: ContrastMode
  fontSizeLevel!: number
  letterSpacingLevel!: number

  minFontSizeLevel = 1
  maxFontSizeLevel = 6

  minLetterSpacingLevel = 1
  maxLetterSpacingLevel = 3

  storageKeyMap = <const>{
    contrastMode: 'contrastMode',
    fontSizeLevel: 'fontSizeLevel',
    letterSpacingLevel: 'letterSpacingLevel',
  }

  private renderer: Renderer2
  private readonly htmlTag = this.document.documentElement
  private readonly bodyTag = this.document.body

  constructor(
    private localStorage: LocalStorageService,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null)
  }

  toggle(): void {
    // TODO: fix click outside
    setTimeout(() => {
      this.settingsOpen.set(!this.settingsOpen())
    }, 20)
  }

  close(): void {
    this.settingsOpen.set(false)
  }

  open(): void {
    this.settingsOpen.set(true)
  }

  get isOpen(): boolean {
    return this.settingsOpen()
  }

  initA11y(): void {
    const [storedContrastMode, storedFontSizeLevel, storedLetterSpacingLevel]: [any, any, any] = [
      this.localStorage.getItem(this.storageKeyMap.contrastMode),
      this.localStorage.getItem(this.storageKeyMap.fontSizeLevel),
      this.localStorage.getItem(this.storageKeyMap.letterSpacingLevel),
    ]

    if (storedContrastMode && storedContrastMode !== 'undefined') {
      this.contrastMode = storedContrastMode
    } else {
      this.contrastMode = ContrastMode.Color
      this.localStorage.setItem(this.storageKeyMap.contrastMode, this.contrastMode)
    }

    if (storedFontSizeLevel && storedFontSizeLevel !== 'undefined') {
      this.fontSizeLevel = storedFontSizeLevel
    } else {
      this.fontSizeLevel = 1
      this.localStorage.setItem(this.storageKeyMap.fontSizeLevel, this.fontSizeLevel)
    }

    if (storedLetterSpacingLevel && storedLetterSpacingLevel !== 'undefined') {
      this.letterSpacingLevel = storedLetterSpacingLevel
    } else {
      this.letterSpacingLevel = 1
      this.localStorage.setItem(this.storageKeyMap.letterSpacingLevel, this.letterSpacingLevel)
    }

    this.renderer.addClass(this.bodyTag, this.contrastMode)
    this.renderer.addClass(this.htmlTag, this.contrastMode)

    this.checkAndSetAccessibilityStatus()
  }

  setContrast(newContrastMode: ContrastMode): void {
    const prevContrastMode = this.contrastMode !== newContrastMode ? this.contrastMode : undefined

    this.contrastMode = newContrastMode
    this.localStorage.setItem(this.storageKeyMap.contrastMode, this.contrastMode)
    this.renderer.addClass(this.htmlTag, this.contrastMode)

    if (prevContrastMode) {
      this.renderer.removeClass(this.htmlTag, prevContrastMode)
    }

    this.itemChangeEvent(prevContrastMode)
  }

  checkAndSetAccessibilityStatus(): void {
    if (this.fontSizeLevel > 1 || this.letterSpacingLevel > 1) {
      this.renderer.addClass(this.htmlTag, 'accessibility')
    } else {
      this.renderer.removeClass(this.htmlTag, 'accessibility')
    }
  }

  decrementFontSizeLevel(): void {
    this.setFontSize(this.fontSizeLevel - 1)
    this.checkAndSetAccessibilityStatus()
  }

  incrementFontSizeLevel(): void {
    this.setFontSize(this.fontSizeLevel + 1)
    this.checkAndSetAccessibilityStatus()
  }

  private validateFontSizeLevel(newFontSizeLevel: number): number {
    if (newFontSizeLevel < this.minFontSizeLevel) {
      return this.minFontSizeLevel
    }

    if (newFontSizeLevel > this.maxFontSizeLevel) {
      return this.maxFontSizeLevel
    }

    return newFontSizeLevel
  }

  setFontSize(newFontSizeLevel: number): void {
    if (newFontSizeLevel < this.minFontSizeLevel) {
      return
    }

    this.fontSizeLevel = this.validateFontSizeLevel(newFontSizeLevel)
    this.localStorage.setItem(this.storageKeyMap.fontSizeLevel, this.fontSizeLevel)

    this.htmlTag.classList.remove(...Array.from(this.htmlTag.classList).filter((cls) => cls.startsWith('font-size-level-')))
    this.htmlTag.classList.add(`font-size-level-${newFontSizeLevel}`)

    this.fontSizeLevelChangeEmitter.emit(this.fontSizeLevel)
  }

  decrementLetterSpacingLevel(): void {
    this.setLetterSpacing(this.letterSpacingLevel - 1)
    this.checkAndSetAccessibilityStatus()
  }

  incrementLetterSpacingLevel(): void {
    this.setLetterSpacing(this.letterSpacingLevel + 1)
    this.checkAndSetAccessibilityStatus()
  }

  private validateLetterSpacingLevel(newLetterSpacingLevel: number): number {
    if (newLetterSpacingLevel < this.minLetterSpacingLevel) {
      return this.minLetterSpacingLevel
    }

    if (newLetterSpacingLevel > this.maxLetterSpacingLevel) {
      return this.maxLetterSpacingLevel
    }

    return newLetterSpacingLevel
  }

  setLetterSpacing(newLetterSpacingLevel: number): void {
    if (newLetterSpacingLevel < this.minLetterSpacingLevel) {
      return
    }

    this.letterSpacingLevel = this.validateLetterSpacingLevel(newLetterSpacingLevel)
    this.localStorage.setItem(this.storageKeyMap.letterSpacingLevel, this.letterSpacingLevel)

    this.htmlTag.classList.remove(...Array.from(this.htmlTag.classList).filter((cls) => cls.startsWith('letter-space-level-')))
    this.htmlTag.classList.add(`letter-space-level-${newLetterSpacingLevel}`)
  }

  reset(): void {
    this.setFontSize(1)
    this.setLetterSpacing(1)
    this.setContrast(ContrastMode.Color)
    this.renderer.removeClass(this.htmlTag, 'accessibility')
  }

  enable(): void {
    this.renderer.addClass(this.bodyTag, this.contrastMode)
    this.renderer.addClass(this.htmlTag, this.contrastMode)
    this.renderer.removeClass(this.bodyTag, ContrastMode.Contrast)
    this.renderer.removeClass(this.htmlTag, ContrastMode.Contrast)
    this.renderer.removeClass(this.bodyTag, ContrastMode.Color)
    this.renderer.removeClass(this.htmlTag, ContrastMode.Color)
    this.setContrast(this.contrastMode)
    this.setFontSize(this.fontSizeLevel)
    this.setLetterSpacing(this.letterSpacingLevel)
  }

  disable(): void {
    this.renderer.removeClass(this.bodyTag, ContrastMode.Contrast)
    this.renderer.removeClass(this.htmlTag, ContrastMode.Contrast)
    this.renderer.addClass(this.bodyTag, ContrastMode.Color)
    this.renderer.addClass(this.htmlTag, ContrastMode.Color)
    this.htmlTag.classList.remove(...Array.from(this.htmlTag.classList).filter((cls) => cls.startsWith('font-size-level-')))
    this.htmlTag.classList.remove(...Array.from(this.htmlTag.classList).filter((cls) => cls.startsWith('letter-space-level-')))
  }

  checkIsActive(): boolean {
    const isContrast = this.bodyTag.classList.contains(ContrastMode.Contrast)
    const isStorageContrast = this.localStorage.getItem('contrastMode') === 'contrast'

    return isContrast && isStorageContrast
  }

  itemChangeEvent(data: ContrastMode | undefined): void {
    this.contrastModeEmitter.emit(data)
  }

  getItemEmitter(): EventEmitter<ContrastMode | undefined> {
    return this.contrastModeEmitter
  }
}
