import { NavigationItem } from './common/header/components/navigation/shared/types'
import { NavigationLabel, NavigationUrl } from './core/constants/navigation'

export const navItems: NavigationItem[] = [
  {
    label: NavigationLabel.TrustedServices,
    url: '',
    items: [
      {
        label: NavigationLabel.PurchaseCertificate,
        url: NavigationUrl.Order,
      },
      {
        label: NavigationLabel.UpdateCertificate,
        url: NavigationUrl.Update,
      },
      {
        label: NavigationLabel.FindCertificate,
        url: NavigationUrl.SearchCertificates,
      },
      {
        label: NavigationLabel.SignDocument,
        url: NavigationUrl.Sign,
      },
      {
        label: NavigationLabel.CheckSign,
        url: NavigationUrl.CheckSign,
      },
      {
        label: NavigationLabel.ChangeStatus,
        url: NavigationUrl.ChangeStatus,
      },
    ],
  },
  {
    label: NavigationLabel.ProtectedMedia,
    url: '',
    items: [
      {
        label: NavigationLabel.Almaz1KPlastic,
        url: NavigationUrl.Device0,
      },
      {
        label: NavigationLabel.Almaz1KMetal,
        url: NavigationUrl.Device1,
      },
      {
        label: NavigationLabel.Almaz1KBluetooth,
        url: NavigationUrl.Device2,
      },
      {
        label: NavigationLabel.Crystal1,
        url: NavigationUrl.Device3,
      },
      {
        label: NavigationLabel.Securetoken337K,
        url: NavigationUrl.Device4,
      },
      {
        label: NavigationLabel.Securetoken338M,
        url: NavigationUrl.Device5,
      },
      {
        label: NavigationLabel.Securetoken338S,
        url: NavigationUrl.Device6,
      },
      {
        label: NavigationLabel.KP382,
        url: NavigationUrl.Device7,
      },
    ],
  },
  {
    label: NavigationLabel.BecomeRepresentative,
    url: NavigationUrl.Partners,
  },
  {
    label: NavigationLabel.News,
    url: NavigationUrl.News,
  },
  {
    label: NavigationLabel.ServicePoints,
    url: NavigationUrl.Contacts,
  },
  {
    label: NavigationLabel.DiiaID,
    url: '',
    items: [
      {
        label: NavigationLabel.AboutDiiaSignature,
        url: NavigationUrl.About,
      },
      {
        label: NavigationLabel.ContractQualifiedServices,
        url: NavigationUrl.Contract,
        isBlank: true,
      },
      {
        label: NavigationLabel.StatementJoiningContract,
        url: NavigationUrl.Statement,
        isBlank: true,
      },
      {
        label: NavigationLabel.DiiaProjectContract,
        url: NavigationUrl.DiiaProject,
        isBlank: true,
      },
      {
        label: NavigationLabel.DiiaEngContract,
        url: NavigationUrl.DiiaEng,
        isBlank: true,
      },
    ],
  },
  {
    label: NavigationLabel.More,
    url: '',
    items: [
      {
        label: NavigationLabel.Software,
        url: NavigationUrl.Software,
      },
      {
        label: NavigationLabel.Regulations,
        url: NavigationUrl.Reglament,
      },
      {
        label: NavigationLabel.SecureKeyMedia,
        url: NavigationUrl.Keys,
      },
      {
        label: NavigationLabel.QuestionsAndAnswers,
        url: NavigationUrl.Faq,
      },
      {
        label: NavigationLabel.WhereSignatureWorks,
        url: NavigationUrl.Partnerships,
      },
      {
        label: NavigationLabel.ContractForms,
        url: NavigationUrl.Contracts,
      },
      {
        label: NavigationLabel.HowElectronicSignatureWorks,
        url: NavigationUrl.DigitalSignature,
      },
    ],
  },
]
